import '../../styles/pages/fall-winter-2023/finance-students-learn-secrets-of-the-trade.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"

import socialBanner from '../../images/fall-winter-2023/social-banners/finance-students-learn-secrets-of-the-trade.jpg';

import AlexMedina from '../../images/fall-winter-2023/finance-students-learn-secrets-of-the-trade/alex-medina.jpg'
import KellyKo from '../../images/fall-winter-2023/finance-students-learn-secrets-of-the-trade/kelly-ko.jpg'
import JefferyVanHarte from '../../images/fall-winter-2023/finance-students-learn-secrets-of-the-trade/jeffery-van-harte.jpg'
import WorldChampionship from '../../images/fall-winter-2023/finance-students-learn-secrets-of-the-trade/world-championship.jpg'
import ctaBanner from '../../images/fall-winter-2023/finance-students-learn-secrets-of-the-trade/cta-banner.jpg'

import heroVideoMP4 from '../../video/fall-winter-2023/tcm/tcm-hero.mp4';
import heroVideoWEBM from '../../video/fall-winter-2023/tcm/tcm-hero.webm';
import heroVideoVTT from 'file-loader!../../video/fall-winter-2023/tcm/tcm-hero.vtt';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Finance Students Learn Secrets of the Trade';
    var pageDesc = 'CSUF’s globally recognized Titan Capital Management teaches students the art of investing and gives them a competitive edge in the financial industry.';
    var slug = 'finance-students-learn-secrets-of-the-trade';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn">
          <div className="text">
            <h1 className="animated fadeIn delay-1s slower">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">{pageDesc}</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey and Valerie Orleans, Photos by Matt Gush</span>
          </div>
          <div className="tint"></div>
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" >
            <source src={heroVideoWEBM} type="video/webm; codecs=vp9,vorbis" />
            <source src={heroVideoMP4} />
            <track kind="captions" srcLang="en" label="English" src={heroVideoVTT} />
          </video>
        </div>
        <article>

          <div className='wrap small'>

            <p><span className='intro-text'>A month after Alex Medina graduated from Cal State Fullerton</span>, he walked through the glass doors at Goldman Sachs to start his career. </p>

            <p>From studying finance to hitting the desk as a private equity analyst for a top-tier investment banking company, one program stood out as the key to Medina’s successful launch into the finance industry — Titan Capital Management.</p> 

            <p>The College of Business and Economics program provides students with hands-on experience in security analysis and portfolio management. Working with approximately $3 million in assets, students decide when and where to invest, and the profits they earn go back to their fellow classmates — funding scholarships and the college’s tutoring center.</p>

            <figure className='align-right align-outside large'>
              <img src={AlexMedina} alt="Alex Medina ’20" />
              <figcaption>Alex Medina ’20 (B.A. business administration-finance)</figcaption>
            </figure>

            <p>“​​Until I joined Titan Capital Management, most of my financial experience had been academic. TCM offered students like me the chance to apply the principles and lessons we learned in finance lectures to real investments,” says Medina ’20 (B.A. business administration-finance). </p> 

            <p>Titan Capital Management (formerly known as the Applied Securities Analysis Program) boasts more than 150 alumni who have become industry professionals in their fields, working at such companies as JPMorgan Chase, Citi, Amazon and Wells Fargo. </p>

            <p>“The program offers more than just the opportunity to analyze investments, but rather a chance to improve in all aspects of professional development, including speaking, writing, presenting and listening,” says Medina, now a business development analyst at California Resource Corp. </p>

            <h2>‘The Best Return Is Giving Back’</h2>
            <p>When students walk into the Titan Capital Management center on campus, they are greeted with a wall message: “The best return is giving back.” </p>
 
            <p>It is a reminder from CSUF alumnus and supporter Jeffrey Van Harte ’80 (B.A. business administration-finance), whose generous $1.5 million gift opened the program’s state-of-the-art investment center 10 years ago.</p>

            <p>“The world is a bigger place than just our own individual endeavors and helping others, especially your fellow Titans and CSUF, is meaningful,” says Van Harte. </p>

            <figure className='align-wide'>
              <img src={JefferyVanHarte} alt="Jeffrey Van Harte ’80" />
              <figcaption>Jeffrey Van Harte ’80 (B.A. business administration-finance)</figcaption>
            </figure>

            <blockquote className='align-left align-outside'>
              <p>This program has noticeably added value in helping students secure that first job.”</p>
              <cite>Jeffrey Van Harte</cite>
            </blockquote>

            <p>As co-chair of Jackson Square Partners, he understands the importance of funding a program that provides aspiring business professionals real-world opportunities to test their knowledge and develop critical thinking and communication skills. </p>

            <p>“Learning how to think about deploying capital to its highest and best use is more an art than a science, and it will serve students well in whatever career path they choose,” says Van Harte. </p>

            <p>Students in the program learn how to assess the economic landscape, draft investment strategies, pitch stock and bond recommendations to companies, and perform in-depth analyses to support their claims. </p>

            <p>“The most satisfying result we have achieved is that students are getting great jobs with high pay after graduation. This program has noticeably added value in helping them secure that first job,” adds Van Harte. </p>

            <p>Van Harte’s championing of student success has helped students like Alexandra Mendoza ’22 (B.A. business administration-finance) launch successful careers right out of college. </p>

            <p>“When it came to portfolio management and asset research, there was a steep learning curve,” says Mendoza, an associate financial planning analyst at Mercer Global Advisors. “But I was able to build my confidence with feedback from mentors and professional financial analysts.”</p>

            <p>With each graduating class, Van Harte says one of his favorite parts about this program is seeing TCM grads give back to their communities and serve those around them as they join a network of more than 70,000 alumni from the College of Business and Economics. </p>

            <h2>Top Six in the World</h2>

            <figure className='align-wide'>
              <img src={WorldChampionship} alt="The Titan Capital Management Team" />
              <figcaption>The 2023 Titan Capital Management team (left to right): students Sam Fedrow, Brandon Leon, Jacquelyn George, Jericho Cezar and faculty adviser Kelly Ko</figcaption>
            </figure>

            <p>Over the past decade, Titan Capital Management teams have won awards at national and global competitions.</p>

            <p>In 2020, the team won its first-ever first-place win in the Chartered Financial Analyst Institute Research Challenge in Los Angeles. The team won that title for the fourth consecutive year in 2023 and qualified for the global competition — joining the ranks of the top six teams in the world among 1,000 teams from the Americas, Asia, Europe, the Middle East and Africa.</p>

            <p>The competition challenges students to research, analyze and value a publicly traded company and present their findings to a panel of industry professionals. Students are evaluated on their report writing, presentation skills and the quality of their research.</p>

            <figure className='align-right align-outside large'>
              <img src={KellyKo} alt="Faculty adviser Kelly Ko" />
              <figcaption>Kelly Ko, lecturer in finance and faculty adviser</figcaption>
            </figure>

            <p>“The CFA Institute Research Challenge is the most prestigious, well-recognized global investment competition,” says Kelly Ko, lecturer in finance and equity director of the Titan Capital Management program. “The team’s demonstration on the big stage, in front of international judges and audience members, was outstanding.” Ko serves as the team’s faculty adviser, and Derek Derman, a partner at Poplar Forest Capital, serves as its industry mentor.</p>

            <p>Led by head captain Jericho Cezar ’23 (B.A. business administration-finance), this year’s team put in more than 600 hours of research, practice and meetings. Other team members included Sam Fedrow ’23 (B.A. business administration-finance), Jacquelyn George ’23 (B.A. business administration-finance) and Brandon Leon ’23 (MBA).</p>

            <p>As a freshman at Cal State Fullerton, Cezar knew he wanted to study investing. Landing a job as a private credit analyst at Goldman Sachs immediately after graduation, Cezar credits his mentors for helping him achieve his goals and teaching him to think like a professional investment analyst.</p>

            <p>“Titan Capital Management helped me become a better investor due to the rigor of the program and the mentorship that we received from the board and faculty directors,” he says.</p>

            <p>Fedrow, a financial analyst for Acuity Advisors, adds, “The competition was easily the highlight of my academic career.” <span className='end-mark' /></p>

            <p><small><em>Edited Oct. 20, 2023 to reflect updated information.</em></small></p>
            
          </div>

        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support Titan Capital Management</span><br />
              <a href="https://give.fullerton.edu/titancapitalmanagement">give.fullerton.edu/titancapitalmanagement</a>
            </p>
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 